/* Add pointer cursor on fake buttons */
.tables-cursor-pointer {
  cursor: pointer;
}

/* Limit multi item selector list panel height */
.list-multy-item-selector-panel .p-dialog-content {
  max-height: 80vh;
  overflow-y: auto;
}


/*
 * Limit very long text to n lines in dataTables
 * Text end with ellipsis ...
 * On mouse over the full text reappears
 */

.table-data-cell-max-lines {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
  /*-webkit-line-clamp: 2;*/
	-webkit-box-orient: vertical;
}

.table-data-cell-max-lines:hover {
	overflow: visible;
  white-space: pre-wrap;
	display: block;
}

/*
 * Display anchor until mouse over
 * On mouse over, display the content in a pannel
 */
 .table-notes-cell-template {
  display: block;
  text-align: center;
}

.table-notes-cell-template .content {
  display: none;
}

.table-notes-cell-template:hover .anchor {
  display: none;
}

.table-notes-cell-template:hover .content {
	position: absolute;
  text-align: left;
  white-space: pre-wrap;
  border-radius: 5px;
	display: block;
	min-width: 50%;
	color: black;
	background-color: white;
	margin-top: -2em;
	margin-left: 1em;
  right: 1em;
  border: solid 1px;
  padding: 1em;
}

