/* Dark menue style */
.layout-menu-button:focus {
	box-shadow: 0 0 0 0.2em #888888 !important;
}

.layout-topbar-icons .p-link:focus {
	box-shadow: 0 0 0 0.2em #888888 !important;
}

.profile-link:focus {
	box-shadow: 0 0 0 0.2em #888888 !important;
}

.profile-expanded .p-link:focus {
	box-shadow: 0 0 0 0.2em #888888 !important;
}

.layout-wrapper .layout-main {
  padding: 1px 8px 0px 8px !important;
}

/* keep footers at the bottom of the page */
.layout-wrapper {
  min-height:100%;
	position: relative;
}

.layout-footer {
    position: absolute;
    bottom: 0;
}

.p-datatable-footer {
  text-align: right !important;
}

/* Dismiss button on the right of the menu side bar */

.layout-menu-button-container {
	display: flex;
  margin-right: 10px;
  justify-content: flex-end;
}

.layout-menu-button {
	cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
	text-decoration: none;
	color: #ffffff;
	transition: color 0.2s;
}

.layout-wrapper .layout-sidebar {
  margin-top: 0px !important;
}

.profile-link {
	outline: 0;
	border: none;
	box-shadow: none !important;
}

.profile-link:focus {
	outline: 0;
	border: none;
	box-shadow: none !important;
}
